import { Input,InputGroup, forwardRef, InputRightAddon, InputProps } from '@chakra-ui/react'
import {CheckCircleIcon, WarningIcon} from '@chakra-ui/icons'

const Phoney = forwardRef<InputProps, null>((props, ref) => (

    <Input                   _placeholder={{color:'gray.500'}}
    color='green.300' variant={props.variant} ref={ref}  {...props} />

))

export const DateP = forwardRef(({isSubmitting, value, onClick,isInvalid,isTouched}, ref) => (
    
    <InputGroup  w='100%' isSubmitting={isSubmitting} isInvalid={isInvalid}>
        <Input                   _placeholder={{color:'gray.500'}}
                  color='green.300' onFocus={onClick} variant="filled" onClick={onClick} ref={ref} value={value} />
    {/* <InputRightAddon>
<CheckCircleIcon color={} />
    </InputRightAddon> */}
    </InputGroup>
    
))

export default Phoney;
import create from 'zustand'
import {devtools, persist} from 'zustand/middleware'


let store = (set) => ({
  editing: true,
  toggleEditing: (val) => set((state) => ({ editing: val ? val : !state.editing})),

  hasBooked: false,
  toggleHasBooked: (val) => set((state) => ({ hasBooked: val ? val : !state.hasBooked})),

  modalRejected: false,
  toggleModalRejected: (val) => set((state) => ({ modalRejected: val ? val : !state.modalRejected})),

  currentUser: null,
  setCurrentUser: (val) => set((state) => ({currentUser: state.currentUser = val})),

  fetchGridData: true,
  setFetchGridData: (val) => set((state) => ({fetchGridData: val ? val : !state.fetchGridData}))
  
})

store = devtools(store)

const useStore = create(store)

export default useStore
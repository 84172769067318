import { useForm, useController} from 'react-hook-form'
import {Radio, RadioGroup, Stack, Wrap} from '@chakra-ui/react'

export default function MyRadio(props) {
    const { field, fieldState } = useController(props);
  
    return (
      <div>
        <RadioGroup {...props} {...field} name={props.name}>
            <Wrap>
            <Radio  value="google">Google/Search Engine</Radio>
            <Radio value="facebook">Facebook</Radio>
            <Radio value="twitter">Twitter</Radio>
            <Radio value="instagram">Instagram</Radio>
            <Radio value="word-of-mouth">Word of Mouth</Radio>
            <Radio value="blog-post">Blog Post</Radio>
            <Radio value="other">Other</Radio>  
            </Wrap>
        {/* <p>{fieldState.isTouched && "Touched"}</p>
        <p>{fieldState.isDirty && "Dirty"}</p>
        <p>{fieldState.invalid ? "invalid" : "valid"}</p> */}
        </RadioGroup>
      </div>
    );
  }
'use client'
import React, { useState, useEffect } from "react";
import {
  PhoneIcon,
  AddIcon,
  WarningIcon,
  WarningTwoIcon,
  CheckCircleIcon,
} from "@chakra-ui/icons";
import {
  Input,
  Icon,
  InputRightAddon,
  InputGroup,
  Textarea,
  Button,
  Heading,
  Text,
  FormControl,
  SimpleGrid,
  FormErrorMessage,
  FormLabel,
  RadioGroup,
  Radio,
  Container,
  Select,
  Flex,
  Box,
  calc,
  Center,
} from "@chakra-ui/react";
import { useForm, Controller, appendErrors } from "react-hook-form";
import SuccessCheck from "./SuccessCheck";
import DatePicker from "react-datepicker";
import { format, getDate, getDay, addDays } from "date-fns";
import {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import PhoneInput from "react-phone-number-input/input";
import Phoney, { DateP } from "../atoms/Phoney";
import MyInput from "../atoms/NewPhone";
import MyRadio from "../atoms/MyRadio";
import * as gtag from '../../lib/gtag'
import emailRegex from "../atoms/EmailRegex";
import useStore from "../../store";
import { useRouter } from "next/router";
import * as fbq from "../seo/Pixel";
const AppointmentRequestForm = ({bg='brand.500'}) => {
  const hasBooked = useStore(state => state.hasBooked )
  const toggleHasBooked = useStore(state => state.toggleHasBooked)
  const currentUser = useStore(state => state.currentUser)
  const setCurrentUser = useStore (state => state.setCurrentUser)
const router = useRouter()

const currentPage = router.asPath
console.log(`currentPage`, currentPage)
  
  const [startDate, setStartDate] = useState(new Date());
  const { register, getValues, handleSubmit, watch, control, formState } =
    useForm({
      defaultValues: {
        apptTime: "unselected",
        apptDate: null,
        firstName: null,
        lastName: null,
        phone: null,
        email: null,
        notes: "",
        source: "google",
        pageSource: currentPage
      },
      mode: "onBlur",
    });
  const today = new Date();
  const [createEvent, setCreateEvent] = React.useState(false)

async function asyncState() {
  var promise = createEvent(true)
  return promise;
  }
  useEffect(() => {
   if(window !== 'undefined') {
  if(formState.isSubmitSuccessful) {
    gtag.generateLead(100.00)
      toggleHasBooked(true)
    }
  }
  }, [createEvent])
  const isProduction = (e) => {
   const truth = e.process.env.NODE_ENV === 'production'
    return truth;
}
  const isWeekday = (date) => {
    const day = getDay(date);
    return day !== 0 && day !== 6;
  };



  



  const submitLeadForm = async (data) => {
    try{
    const request = await fetch("/api/prisma/appt/create", {
      method: "POST",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" },
    });

    const result = await request.json()
    
    if(result){
      setCreateEvent(true)
      const sendMail = await fetch('/api/email/appt', {method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(data)})
      console.log(sendMail)
      // console.log(sendMail.json())        
      // const mailResults = await sendMail.json()
      // console.log(`mailResults`,mailResults)
      
    router.replace('/success')
    } else console.log('failure to submit appt form')

     
    
  }catch(error) {
    console.log('Sorry! You have already signed up.')
    console.log(error)
    return;
  } 
 
  };

  const watchSelector = watch(["apptTime", "apptDate"]);


  

  const show = true


  return (
    <>
    {/* { isProduction && formState?.isSubmitSuccessful ? (
      <Flex bg='brand.500' w='100%' justify='center' align='center'>
        <Flex align='center' justify='space-evenly' flexDir='column' w='100%' minH='25vh'>
          <Heading textAlign={'center'}>Thank you!</Heading>
         <Center><SuccessCheck /></Center>
          <Text textAlign={'center'}>Your appointment request has been sent. Check your inbox for a copy of your response. We'll be in touch with you soon.</Text>
        </Flex>
      </Flex>
    ) :( */}
      <Flex id='appointment-form' w='100%' bg={bg} align='center' justify={'center'}>
    <form onSubmit={handleSubmit(submitLeadForm)}>
      <Container  py={5} maxW="container.lg">
        <SimpleGrid columns={[1, 1, 2]} spacingX={5}>
          <Flex gridGap={3} flexDir="column">
            <SimpleGrid columnGap={3} columns={2}>
              <FormControl
                isInvalid={formState.errors.firstName}
                isRequired={true}
              >
                <FormLabel>First Name</FormLabel>
                <InputGroup>
                  <Input
                                    _placeholder={{color:'gray.500'}}
                                    color='green.300'
                    variant="filled"
                    type="text"
                    {...register("firstName", { required: true })}
                    placeholder="First Name"
                  />
                  {formState.touchedFields.firstName &&
                  !formState.errors.firstName ? (
                    <InputRightAddon>
                      <CheckCircleIcon color="green" />
                    </InputRightAddon>
                  ) : !formState.touchedFields.firstName ? (
                    <InputRightAddon>
                      <CheckCircleIcon color="blackAlpha.200" />
                    </InputRightAddon>
                  ) : formState.touchedFields.firstName &&
                    formState.errors.firstName ? (
                    <InputRightAddon>
                      <WarningIcon color="red" />
                    </InputRightAddon>
                  ) : null}
                </InputGroup>
                {formState.errors.firstName && (
                  <FormErrorMessage>First name is required.</FormErrorMessage>
                )}
              </FormControl>
              <FormControl
                isInvalid={formState.errors.lastName}
                isRequired={true}
              >
                <FormLabel>Last Name</FormLabel>
                <InputGroup>
                  <Input
                                    _placeholder={{color:'gray.500'}}
                                    color='green.300'
                    variant="filled"
                    type="text"
                    {...register("lastName", { required: true })}
                    placeholder="Last Name"
                  />
                  {formState.touchedFields.lastName &&
                  !formState.errors.lastName ? (
                    <InputRightAddon>
                      <CheckCircleIcon color="green" />
                    </InputRightAddon>
                  ) : !formState.touchedFields.lastName ? (
                    <InputRightAddon>
                      <CheckCircleIcon color="blackAlpha.200" />
                    </InputRightAddon>
                  ) : formState.touchedFields.lastName &&
                    formState.errors.lastName ? (
                    <InputRightAddon>
                      <WarningIcon color="red" />
                    </InputRightAddon>
                  ) : null}
                </InputGroup>
                {formState.errors.lastName && (
                  <FormErrorMessage>Last name is required.</FormErrorMessage>
                )}
              </FormControl>
            </SimpleGrid>
            <FormControl isInvalid={formState.errors.email} isRequired={true}>
              <FormLabel>Email Address</FormLabel>
              <InputGroup>
                <Input
                  name="email"
                  variant="filled"
                  type="email"
                  {...register("email", {
                    required: true,
                    pattern:
                      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  })}
                  placeholder="Email Address"
                  _placeholder={{color:'gray.500'}}
                  color='green.300'
                />

                {formState.touchedFields.email && !formState.errors.email ? (
                  <InputRightAddon>
                    <CheckCircleIcon color="green" />
                  </InputRightAddon>
                ) : !formState.touchedFields.email ? (
                  <InputRightAddon>
                    <CheckCircleIcon color="blackAlpha.200" />
                  </InputRightAddon>
                ) : formState.touchedFields.email && formState.errors.email ? (
                  <InputRightAddon>
                    <WarningIcon color="red" />
                  </InputRightAddon>
                ) : null}
              </InputGroup>
              {formState.errors.email &&
                formState.errors.email.type === "pattern" && (
                  <FormErrorMessage>
                    Please enter a valid email address.
                  </FormErrorMessage>
                )}
              {formState.errors.email &&
                formState.errors.email.type === "required" && (
                  <FormErrorMessage>
                    Email address is required.
                  </FormErrorMessage>
                )}
            </FormControl>
            <FormControl isInvalid={formState.errors.phone} isRequired={true}>
              <FormLabel>Phone Number</FormLabel>
              <MyInput style={{color:'green.300'}}
                placeholder="Phone Number"
                variant="filled"
                rules={{
                  required: true,
                  validate: (value) => isPossiblePhoneNumber(value) === true,
                }}
                validPhone={!formState.errors.phone}
                touchedPhone={formState.touchedFields.phone}
                name="phone"
                control={control}
              />
              {formState.errors.phone &&
                formState.errors.phone.type === "validate" && (
                  <FormErrorMessage>
                    Please enter a valid phone number.
                  </FormErrorMessage>
                )}
              {formState.errors.phone &&
                formState.errors.phone.type === "required" && (
                  <FormErrorMessage>Phone number is required.</FormErrorMessage>
                )}
              {/* <Controller 
                    name="phoneinput"
                    control={control}
                    rules={{
                        validate: (value) => isValidPhoneNumber(value)
                    }}
                    render={({ field: {onChange, value } }) => (
                        <PhoneInput
                          value={value}
                          onChange={onChange}
                          country="US"
                          id="phoneinput"
                          inputComponent={ControlledInput}
                        />
                    )}
                  /> */}
            </FormControl>
            <FormControl isRequired={true}>
              <Controller
                name="apptDate"
                control={control}
                render={({ field: { onChange, value, onClick } }) => (
                  <div onClick={onClick}>
                    <FormLabel>Preferred Date</FormLabel>
                    <DatePicker
                    className='gle'
                      customInput={
                        <DateP isTouched={false} isInvalid={false} />
                      }
                      withPortal
                      onChange={(date) => onChange(date)}
                      selected={value}
                      minDate={new Date()}
                      filterDate={isWeekday}
                      openToDate={new Date()}
                      dateFormat="MM/dd/yyyy"
                    />
                  </div>
                )}
              />
            </FormControl>
            <FormControl
              isInvalid={watchSelector[1] === "unselected"}
              isRequired={true}
            >
              <FormLabel>Preferred Time</FormLabel>
              <InputGroup>
                <Select
                  placeholder="Select a time..."
                  {...register("apptTime", {
                    required: true,
                    validate: (value) =>
                      value === "morning" ||
                      value === "afternoon" ||
                      value === "evening",
                  })}
                  variant="filled"
                  _placeholder={{color:'gray.500'}}
                  color='green.300'
                  defaultValue="unselected"
                  placeHolder="Preferred Time"
                >
                  {/* <option value='unselected'>Select a time...</option> */}
                  <option value="morning">Morning</option>
                  <option value="afternoon">Afternoon</option>
                  <option value="evening">Evening</option>
                </Select>
                {/* <InputRightAddon>
                 
                  {initTime === 'unselected' && !formState.touchedFields.apptTime ? <CheckCircleIcon color='blackAlpha.200'/> : initTime === 'unselected' && formState.touchedFields.apptTime ? <WarningIcon color='red' /> :  initTime === 'morning' || 'afternoon' || 'evening' ? <CheckCircleIcon color='green' />: null}
                </InputRightAddon> */}
              </InputGroup>
              {formState.errors.apptTime && (
                <FormErrorMessage>Please choose a valid appointment time.</FormErrorMessage>
              )}
            </FormControl>
            <FormControl pt={5}>
              <FormLabel>How did you find us?</FormLabel>
              <MyRadio
                colorScheme="green"
                control={control}
                name="source"
              />
            </FormControl>
          </Flex>
          <Flex pt={[5, 5, 0]}>
            <FormControl>
              <FormLabel>Notes for the Doctor</FormLabel>
              <Textarea
                resize="none"
                variant="filled"
                {...register("notes")}
                h="80%"
                size="lg"
                placeholder="Notes..."
                _placeholder={{color:'gray.500'}}
                color='green.300'
              />
            </FormControl>
          </Flex>
        </SimpleGrid>
      </Container>
      <Container py={7} maxW="container.lg">
        <Flex w="100%" justify="center" align="center">
          <Button color='black' isLoading={formState.isSubmitting}  w="50%" type="submit">
            Submit
          </Button>
        </Flex>
      </Container>
    </form>
    </Flex>
    {/* )} */}
    </>
  );
};

export default AppointmentRequestForm;
